var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { "no-shadow": "", "card-border": "" } }, [
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full align-center my-2" }, [
            _c("span", [_vm._v("Tickets & Attractions Rule Criteria:")]),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Suppliers:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { rules: { required: false } },
                      expression: "{ rules: { required: false } }",
                    },
                  ],
                  attrs: {
                    multiple: "",
                    name: "ticketSuppliers",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.ticketSupplier,
                  },
                  model: {
                    value:
                      _vm.ticketExtraLocalForm.ticketsRuleCriteria.suppliers,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ticketExtraLocalForm.ticketsRuleCriteria,
                        "suppliers",
                        $$v
                      )
                    },
                    expression:
                      "ticketExtraLocalForm.ticketsRuleCriteria.suppliers",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Ticket Types:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { rules: { required: false } },
                      expression: "{ rules: { required: false } }",
                    },
                  ],
                  attrs: {
                    multiple: "",
                    name: "ticketTypes",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.ticketLocalHelper.enums.ticketTypes,
                  },
                  model: {
                    value:
                      _vm.ticketExtraLocalForm.ticketsRuleCriteria.ticketTypes,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ticketExtraLocalForm.ticketsRuleCriteria,
                        "ticketTypes",
                        $$v
                      )
                    },
                    expression:
                      "ticketExtraLocalForm.ticketsRuleCriteria.ticketTypes",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Country:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.countryCode,
                  },
                  model: {
                    value:
                      _vm.ticketExtraLocalForm.ticketsRuleCriteria.countries,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ticketExtraLocalForm.ticketsRuleCriteria,
                        "countries",
                        $$v
                      )
                    },
                    expression:
                      "ticketExtraLocalForm.ticketsRuleCriteria.countries",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("countries"))),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "vx-row mb-6" },
          [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Locations:")]),
            ]),
            _vm._l(
              _vm.ticketExtraLocalForm.ticketsRuleCriteria.locations,
              function (ticklocation, ticklocationIndex) {
                return [
                  _c(
                    "div",
                    {
                      key: ticklocationIndex + "0",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("MatchType"),
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true } },
                                expression: "{ rules: { required: true} }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "tickLocationMatch_" + ticklocationIndex,
                              options: _vm.ticketLocalHelper.enums.matchTypes,
                              reduce: (option) => option.code,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.ticketExtraLocalForm.ticketsRuleCriteria
                                  .locations[ticklocationIndex].matchType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ticketExtraLocalForm.ticketsRuleCriteria
                                    .locations[ticklocationIndex],
                                  "matchType",
                                  $$v
                                )
                              },
                              expression:
                                "\n                ticketExtraLocalForm.ticketsRuleCriteria.locations[\n                  ticklocationIndex\n                ].matchType\n              ",
                            },
                          }),
                          _vm.errors.has(
                            "tickLocationMatch_" + ticklocationIndex
                          )
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first(
                                          "tickLocationMatch_" +
                                            ticklocationIndex
                                        )
                                        .replace(
                                          "tickLocationMatch_" +
                                            ticklocationIndex,
                                          "Location match type"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: ticklocationIndex + "1",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("Value"),
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true, max: 25 } },
                                expression:
                                  "{ rules: { required: true, max: 25 } }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "tickLocationValue_" + ticklocationIndex,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.ticketExtraLocalForm.ticketsRuleCriteria
                                  .locations[ticklocationIndex].value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ticketExtraLocalForm.ticketsRuleCriteria
                                    .locations[ticklocationIndex],
                                  "value",
                                  $$v
                                )
                              },
                              expression:
                                "\n                ticketExtraLocalForm.ticketsRuleCriteria.locations[\n                  ticklocationIndex\n                ].value\n              ",
                            },
                          }),
                          _vm.errors.has(
                            "tickLocationValue_" + ticklocationIndex
                          )
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first(
                                          "tickLocationValue_" +
                                            ticklocationIndex
                                        )
                                        .replace(
                                          "tickLocationValue_" +
                                            ticklocationIndex,
                                          "value"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: ticklocationIndex + "2",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8",
                              class: _vm.readOnly
                                ? "opacity-50 cursor-not-allowed"
                                : "",
                              attrs: {
                                size: "small",
                                type: "border",
                                color: "danger",
                                disabled: _vm.readOnly,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removetickDynamicFields(
                                    ticklocationIndex,
                                    "ticklocation"
                                  )
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              }
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full my-3" },
              [
                _c(
                  "vs-button",
                  {
                    class: _vm.readOnly ? "opacity-50 cursor-not-allowed" : "",
                    attrs: {
                      size: "small",
                      type: "border",
                      color: "success",
                      disabled: _vm.readOnly,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addtickDynamicFields("ticklocation")
                      },
                    },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }