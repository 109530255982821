var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { "no-shadow": "", "card-border": "" } }, [
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full align-center my-2" }, [
            _c("span", [_vm._v("Taxis & Transfers Rule Criteria:")]),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Suppliers:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { rules: { required: false } },
                      expression: "{ rules: { required: false } }",
                    },
                  ],
                  attrs: {
                    multiple: "",
                    name: "transferSuppliers",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.transferSupplier,
                  },
                  model: {
                    value: _vm.transferLocalForm.transferRuleCriteria.suppliers,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.transferLocalForm.transferRuleCriteria,
                        "suppliers",
                        $$v
                      )
                    },
                    expression:
                      "transferLocalForm.transferRuleCriteria.suppliers",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Transfer Types:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { rules: { required: false } },
                      expression: "{ rules: { required: false } }",
                    },
                  ],
                  attrs: {
                    multiple: "",
                    name: "transferTypes",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.transferLocalHelper.enums.transferTypes,
                  },
                  model: {
                    value:
                      _vm.transferLocalForm.transferRuleCriteria.transferTypes,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.transferLocalForm.transferRuleCriteria,
                        "transferTypes",
                        $$v
                      )
                    },
                    expression:
                      "transferLocalForm.transferRuleCriteria.transferTypes",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Transfer Durations:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    options: _vm.transferLocalHelper.numberArray(0, 365),
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value:
                      _vm.transferLocalForm.transferRuleCriteria
                        .transferDurations,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.transferLocalForm.transferRuleCriteria,
                        "transferDurations",
                        $$v
                      )
                    },
                    expression:
                      "transferLocalForm.transferRuleCriteria.transferDurations",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("transferDurations"))),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Country:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.countryCode,
                  },
                  model: {
                    value: _vm.transferLocalForm.transferRuleCriteria.countries,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.transferLocalForm.transferRuleCriteria,
                        "countries",
                        $$v
                      )
                    },
                    expression:
                      "transferLocalForm.transferRuleCriteria.countries",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("countries"))),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "vx-row mb-6" },
          [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Locations:")]),
            ]),
            _vm._l(
              _vm.transferLocalForm.transferRuleCriteria.locations,
              function (translocation, translocationIndex) {
                return [
                  _c(
                    "div",
                    {
                      key: translocationIndex + "0",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("MatchType"),
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true } },
                                expression: "{ rules: { required: true} }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "transLocationMatch_" + translocationIndex,
                              options: _vm.transferLocalHelper.enums.matchTypes,
                              reduce: (option) => option.code,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.transferLocalForm.transferRuleCriteria
                                  .locations[translocationIndex].matchType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transferLocalForm.transferRuleCriteria
                                    .locations[translocationIndex],
                                  "matchType",
                                  $$v
                                )
                              },
                              expression:
                                "\n                transferLocalForm.transferRuleCriteria.locations[\n                  translocationIndex\n                ].matchType\n              ",
                            },
                          }),
                          _vm.errors.has(
                            "transLocationMatch_" + translocationIndex
                          )
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first(
                                          "transLocationMatch_" +
                                            translocationIndex
                                        )
                                        .replace(
                                          "transLocationMatch_" +
                                            translocationIndex,
                                          "Location match type"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: translocationIndex + "1",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("Value"),
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true, max: 25 } },
                                expression:
                                  "{ rules: { required: true, max: 25 } }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "transLocationValue_" + translocationIndex,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.transferLocalForm.transferRuleCriteria
                                  .locations[translocationIndex].value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transferLocalForm.transferRuleCriteria
                                    .locations[translocationIndex],
                                  "value",
                                  $$v
                                )
                              },
                              expression:
                                "\n                transferLocalForm.transferRuleCriteria.locations[\n                  translocationIndex\n                ].value\n              ",
                            },
                          }),
                          _vm.errors.has(
                            "transLocationValue_" + translocationIndex
                          )
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first(
                                          "transLocationValue_" +
                                            translocationIndex
                                        )
                                        .replace(
                                          "transLocationValue_" +
                                            translocationIndex,
                                          "value"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: translocationIndex + "2",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8",
                              class: _vm.readOnly
                                ? "opacity-50 cursor-not-allowed"
                                : "",
                              attrs: {
                                size: "small",
                                type: "border",
                                color: "danger",
                                disabled: _vm.readOnly,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removetransDynamicFields(
                                    translocationIndex,
                                    "translocation"
                                  )
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              }
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full my-3" },
              [
                _c(
                  "vs-button",
                  {
                    class: _vm.readOnly ? "opacity-50 cursor-not-allowed" : "",
                    attrs: {
                      size: "small",
                      type: "border",
                      color: "success",
                      disabled: _vm.readOnly,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addtransDynamicFields("translocation")
                      },
                    },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "vx-row mb-6" },
          [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Transfer Companies:")]),
            ]),
            _vm._l(
              _vm.transferLocalForm.transferRuleCriteria.transferCompanies,
              function (transCompany, transCompanyIndex) {
                return [
                  _c(
                    "div",
                    {
                      key: transCompanyIndex + "0",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("MatchType"),
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true } },
                                expression: "{ rules: { required: true} }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "transCompanyMatch_" + transCompanyIndex,
                              options: _vm.transferLocalHelper.enums.matchTypes,
                              reduce: (option) => option.code,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.transferLocalForm.transferRuleCriteria
                                  .transferCompanies[transCompanyIndex]
                                  .matchType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transferLocalForm.transferRuleCriteria
                                    .transferCompanies[transCompanyIndex],
                                  "matchType",
                                  $$v
                                )
                              },
                              expression:
                                "\n                transferLocalForm.transferRuleCriteria.transferCompanies[\n                  transCompanyIndex\n                ].matchType\n              ",
                            },
                          }),
                          _vm.errors.has(
                            "transCompanyMatch_" + transCompanyIndex
                          )
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first(
                                          "transCompanyMatch_" +
                                            transCompanyIndex
                                        )
                                        .replace(
                                          "transCompanyMatch_" +
                                            transCompanyIndex,
                                          "Company match type"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: transCompanyIndex + "1",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("label", { staticClass: "text-sm" }, [
                            _vm._v("Value"),
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { rules: { required: true, max: 25 } },
                                expression:
                                  "{ rules: { required: true, max: 25 } }",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "transCompanyValue_" + transCompanyIndex,
                              disabled: _vm.readOnly,
                            },
                            model: {
                              value:
                                _vm.transferLocalForm.transferRuleCriteria
                                  .transferCompanies[transCompanyIndex].value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transferLocalForm.transferRuleCriteria
                                    .transferCompanies[transCompanyIndex],
                                  "value",
                                  $$v
                                )
                              },
                              expression:
                                "\n                transferLocalForm.transferRuleCriteria.transferCompanies[\n                  transCompanyIndex\n                ].value\n              ",
                            },
                          }),
                          _vm.errors.has(
                            "transCompanyValue_" + transCompanyIndex
                          )
                            ? _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors
                                        .first(
                                          "transCompanyValue_" +
                                            transCompanyIndex
                                        )
                                        .replace(
                                          "transCompanyValue_" +
                                            transCompanyIndex,
                                          "value"
                                        )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: transCompanyIndex + "2",
                      staticClass: "vx-col w-full md:w-1/3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mt-8",
                              class: _vm.readOnly
                                ? "opacity-50 cursor-not-allowed"
                                : "",
                              attrs: {
                                size: "small",
                                type: "border",
                                color: "danger",
                                disabled: _vm.readOnly,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removetransDynamicFields(
                                    transCompanyIndex,
                                    "transcompany"
                                  )
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              }
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full my-3" },
              [
                _c(
                  "vs-button",
                  {
                    class: _vm.readOnly ? "opacity-50 cursor-not-allowed" : "",
                    attrs: {
                      size: "small",
                      type: "border",
                      color: "success",
                      disabled: _vm.readOnly,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addtransDynamicFields("transcompany")
                      },
                    },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }