var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { attrs: { "no-shadow": "", "card-border": "" } }, [
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full align-center my-2" }, [
            _c("span", [_vm._v("Airport Extras Rule Criteria:")]),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Suppliers:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.airportSupplier,
                  },
                  model: {
                    value:
                      _vm.airportExtraLocalForm.airportExtrasRuleCriteria
                        .suppliers,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.airportExtraLocalForm.airportExtrasRuleCriteria,
                        "suppliers",
                        $$v
                      )
                    },
                    expression:
                      "\n              airportExtraLocalForm.airportExtrasRuleCriteria.suppliers\n            ",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Airport Extra Types:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { rules: { required: false } },
                      expression: "{ rules: { required: false } }",
                    },
                  ],
                  attrs: {
                    multiple: "",
                    name: "airportExtra",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options:
                      _vm.airportExtraLocalHelper.enums.airportExtraTypes,
                  },
                  model: {
                    value:
                      _vm.airportExtraLocalForm.airportExtrasRuleCriteria
                        .extraTypes,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.airportExtraLocalForm.airportExtrasRuleCriteria,
                        "extraTypes",
                        $$v
                      )
                    },
                    expression:
                      "\n              airportExtraLocalForm.airportExtrasRuleCriteria.extraTypes\n            ",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col w-full md:w-1/2" }, [
            _c("div", { staticClass: "vx-col w-full text-sm" }, [
              _c("span", [_vm._v("Airports:")]),
            ]),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    reduce: (x) => x.code,
                    disabled: _vm.readOnly,
                    options: _vm.priceRulesFormData.airportCode,
                  },
                  model: {
                    value:
                      _vm.airportExtraLocalForm.airportExtrasRuleCriteria
                        .airports,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.airportExtraLocalForm.airportExtrasRuleCriteria,
                        "airports",
                        $$v
                      )
                    },
                    expression:
                      "airportExtraLocalForm.airportExtrasRuleCriteria.airports",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("airports"))),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }